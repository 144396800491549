import '../styles/global.css';

import FlowbiteContext from '@drugfreesleep/modules/flowbite/FlowbiteContext';
import { getApolloClient } from '@drugfreesleep/modules/graphql/apollo';
import { mergeWithCache } from '@drugfreesleep/modules/graphql/fetchNote';
import type { AppProps } from 'next/app';

const MyApp = ({ Component, pageProps }: AppProps) => {
  if (pageProps.apolloCache) {
    mergeWithCache(getApolloClient().cache, pageProps.apolloCache);
  }
  return (
    <FlowbiteContext>
      <Component {...pageProps} />
    </FlowbiteContext>
  );
};

export default MyApp;
